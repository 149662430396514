<template>
	<div class="page">
        <div class="content">
			<el-form inline class="tb-form" label-width="90px" v-model="searchInfo" label-position="left">
				<el-form-item label="话题标题">
					<el-input placeholder="请输入话题标题" v-model="searchInfo.title"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="searchInfo.status">
						<el-option
                          v-for="item in statusList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="起止时间:">
                    <el-date-picker
                          v-model="searchInfo.date"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                        </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="resetParams">重置</el-button>
                </el-form-item>
			</el-form>
            <div class="add">
                <el-button
                        icon="el-icon-plus"
                        type="primary"
                        @click="add">
                    创建话题
                </el-button>
            </div>
            <div class="tablebox">
                <el-table :data="list" class="tb-table" @row-dblclick="edit">
                    <el-table-column prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column prop="title" label="话题标题" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="subtitle" label="话题简介" show-overflow-tooltip></el-table-column>
                    <el-table-column label="起止时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{scope.row.start_time}} 至 {{scope.row.end_time}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="sort" label="排序" width="60"></el-table-column>
                    <el-table-column label="状态" width="60">
                        <template slot-scope="scope">
                            {{scope.row.status | getStatusName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="130">
                        <template slot-scope="scope">
                            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                            <el-button type="text" @click="toggleStatus(scope.row)">{{scope.row.status ? "下架" : "上架"}}</el-button>
                            <el-button type="text" @click="deleteRow(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="curPage"
                        :page-size="20"
                        :total="totalCount"
                        @current-change="onSearch"
                />
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
                curPage:1,
                totalCount:0,
				searchInfo:{
					title:'',
					status:-1,
                    date:[]
				},
				statusList:[
                    {value:-1,name:'全部'},
					{value:0,name:'无效'},
					{value:1,name:'有效'}],
                list:[],
                selectRow:{}
			}
		},
        mounted() {
          this.onSearch()  
        },
        methods:{
            resetParams() {
                this.curPage = 1
                this.searchInfo.title = ''
                this.searchInfo.status = -1
                this.searchInfo.date = []
            },
            async getList() {
                const url = "admin/feed/topic/list"
                const params = {
                                title: this.searchInfo.title,
                                page_no: this.curPage -1,
                                page_size:20
                                };
                if (this.searchInfo.date && this.searchInfo.date.length > 0) {
                    //需要分割时间
                    params.start_time = this.searchInfo.date[0]
                    params.end_time = this.searchInfo.date[1]
                }
                if (this.searchInfo.status >= 0) {
                    params.status = this.searchInfo.status
                }
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.list = data.list
                    this.totalCount = data.total_count
                }
            },
            add() {
                this.$router.push('/feedTopic/add')
            },
            edit(row) {
                this.$router.push({path:'/feedTopic/add', query:{id:row.id}})
            },
            deleteRow(row) {
                this.$confirm('您确定删除该条数据吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                      this.editStatus(row.id, -1)
                  })
            },
            toggleStatus(row) {
              let status = row.status === 0 ? 1 : 0;
                this.editStatus(row.id, status)
            },
            editStatus(id, status) {
                let action = ''
                if (status === -1) {
                    action = '删除'
                } else if (status === 0) {
                    action = '下架'
                } else if (status === 1) {
                    action = '上架'
                }
                this.$confirm('您确定要' + action + '这条评论吗？', '', {
                    onfirmButtonText: '确定',
                              cancelButtonText: '取消',
                              type: 'warning'
                }).then(() => {
                    const url = 'admin/feed/topic/edit'
                    const params = {id: id, status: status}
                    this.$https.post(url, params).then(res => {
                        if (res) {
                            this.$message.success(action + '成功')
                            this.getList()
                        }
                    })
                })
            },
            onSearch() {
                this.getList()
            }
        },
        filters:{
            getStatusName(status) {
                return status === 0 ? '无效' : '有效'
            }
        }
	}
</script>

<style>
</style>
